<template>
    <div class="index" style="background-color: #fff;">
        <div class="circle_box">
            <div class="container row align-items-center">
                <div
                    class="col-12 col-lg-8 order-2 order-lg-1 mt-3 text-center"
                >
                    <svg
                        viewBox="0 0 1776 1648.14"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:svg="http://www.w3.org/2000/svg"
                        enable-background="new 0 0 1776 1648.14"
                        version="1.1"
                        xml:space="preserve"
                    >
                        <defs>
                            <path
                                d="m734,564.36c0,0 177.5,-105 337.5,10c160,115 127.5,275 126,273.14c-1.5,-1.86 4,74.36 -43.5,131.86"
                                id="line2_1"
                            />
                            <path
                                d="m758,615.86c157,-101.25 358.5,8 377,165"
                                id="line2_2"
                            />
                            <path
                                d="m1155,814.86c0,0 36,159 -139,257"
                                id="line2_3"
                            />
                            <path
                                d="m727.46,601.23c-9.8,-0.98 -175.26,115.94 -114.11,320.99c61.16,205.05 312.66,233.48 388.64,171.22"
                                id="line2_4"
                            />
                            <path
                                d="m561,738.86c-30,75.5 -29,215.94 69,334.88c98,118.94 243.75,122.22 334.63,104.27c90.88,-17.95 136.88,-40.14 221.38,-153.14"
                                id="line2_5"
                            />
                        </defs>
                        <g @mouseover="over(1)" @mouseout="over(0)">
                            <!-- 1 -->
                            <path
                                fill="#e89323"
                                d="m1256.44,835.07c0,70.99 -20.08,137.28 -54.86,193.52l-55.42,-35.36c28.26,-46.03 44.55,-100.19 44.55,-158.16c0,-14.34 -1,-28.45 -2.93,-42.26c-20.56,-147.18 -146.94,-260.45 -299.78,-260.45c-62.56,0 -120.7,18.98 -168.95,51.5l-36.68,-54.54c58.73,-39.59 129.48,-62.69 205.63,-62.69c203.48,0 368.44,164.96 368.44,368.44z"
                                transform="matrix(1 0 0 1 0 0)"
                            />
                            <text text-anchor="middle">
                                <textPath
                                    href="#line2_1"
                                    startOffset="50%"
                                    font-size="35px"
                                    font-weight="bold"
                                >
                                    {{
                                        $store.state.language.index.circle
                                            .machining
                                    }}
                                </textPath>
                            </text>
                        </g>
                        <g @mouseover="over(2)" @mouseout="over(0)">
                            <!-- 2 -->
                            <path
                                fill="#B0AFAD"
                                d="m1187.78,792.81l-57.53,7.76c-16.77,-118.78 -118.84,-210.16 -242.25,-210.16c-50.57,0 -97.55,15.34 -136.55,41.62l-32.4,-48.17c48.25,-32.52 106.39,-51.5 168.95,-51.5c152.84,0 279.22,113.27 299.78,260.45z"
                            />
                            <text text-anchor="middle">
                                <textPath
                                    href="#line2_2"
                                    startOffset="50%"
                                    font-size="35px"
                                    font-weight="bold"
                                >
                                    {{
                                        $store.state.language.index.circle
                                            .high_torque
                                    }}
                                </textPath>
                            </text>
                        </g>
                        <g @mouseover="over(3)" @mouseout="over(0)">
                            <!-- 3 -->
                            <path
                                fill="#CCCCCC"
                                d="m1190.71,835.07c0,57.97 -16.29,112.13 -44.55,158.16c-31.26,50.91 -77.15,91.86 -131.82,117l-23,-53.33c83.48,-38.96 141.32,-123.64 141.32,-221.83c0,-11.71 -0.82,-23.23 -2.41,-34.5l57.53,-7.76c1.93,13.81 2.93,27.92 2.93,42.26z"
                            />
                            <text
                                text-anchor="middle"
                                transform="translate(-7,-2)"
                            >
                                <textPath
                                    href="#line2_3"
                                    startOffset="50%"
                                    font-weight="bold"
                                    :style="
                                        $store.state.lan == 'en'
                                            ? 'font-size:20px'
                                            : 'font-size:35px'
                                    "
                                >
                                    {{
                                        $store.state.language.index.circle.engine_casing
                                            .split("")
                                            .reverse()
                                            .join("")
                                    }}
                                </textPath>
                            </text>
                        </g>
                        <g @mouseover="over(4)" @mouseout="over(0)">
                            <!-- 4 -->
                            <path
                                fill="#868687"
                                d="m1014.34,1110.23c-38.45,17.69 -81.24,27.55 -126.34,27.55c-167.18,0 -302.71,-135.53 -302.71,-302.71c0,-31.9 4.93,-62.65 14.09,-91.52c20.84,-65.82 63.59,-121.9 119.67,-159.69l32.4,48.17c-65.22,43.95 -108.11,118.49 -108.11,203.04c0,135.12 109.54,244.66 244.66,244.66c36.93,0 71.95,-8.18 103.34,-22.83l23,53.33z"
                            />
                            <text text-anchor="middle">
                                <textPath
                                    href="#line2_4"
                                    startOffset="50%"
                                    font-size="35px"
                                    font-weight="bold"
                                >
                                    {{
                                        $store.state.language.index.circle
                                            .high_speed
                                    }}
                                </textPath>
                            </text>
                        </g>
                        <g @mouseover="over(5)" @mouseout="over(0)">
                            <!-- 5 -->
                            <path
                                fill="#dc5726"
                                d="m1201.58,1028.59c-64.92,104.98 -181.09,174.92 -313.58,174.92c-203.48,0 -368.44,-164.96 -368.44,-368.44c0,-38.83 6.01,-76.26 17.15,-111.4l62.67,19.88c-9.16,28.87 -14.09,59.62 -14.09,91.52c0,167.18 135.53,302.71 302.71,302.71c45.1,0 87.89,-9.86 126.34,-27.55c54.67,-25.14 100.56,-66.09 131.82,-117l55.42,35.36z"
                            />
                            <text text-anchor="middle">
                                <textPath
                                    href="#line2_5"
                                    startOffset="50%"
                                    font-size="35px"
                                    font-weight="bold"
                                >
                                    {{
                                        $store.state.language.index.circle
                                            .automotive
                                    }}
                                </textPath>
                            </text>
                        </g>
                        <g>
                            <path
                                fill="#f2c890"
                                d="m719.05,583.86c-56.08,37.79 -98.83,93.87 -119.67,159.69l-62.67,-19.88c25.37,-80.11 77.4,-148.36 145.66,-194.35l36.68,54.54z"
                            />
                        </g>
                        <!-- 中間的logo -->
                        <g onclick="location.href = '/'">
                            <path
                                fill="#ffffff"
                                d="m1132.66,835.07c0,98.19 -57.84,182.87 -141.32,221.83c-31.39,14.65 -66.41,22.83 -103.34,22.83c-135.12,0 -244.66,-109.54 -244.66,-244.66c0,-84.55 42.89,-159.09 108.11,-203.04c39,-26.28 85.98,-41.62 136.55,-41.62c123.41,0 225.48,91.38 242.25,210.16c1.59,11.27 2.41,22.79 2.41,34.5z"
                            />
                            <image
                                xlink:href="/img/index_center.png"
                                x="700"
                                y="720"
                                height="230px"
                                width="354px"
                            ></image>
                        </g>
                        <g class="out_ring">
                            <g
                                class="img_btn Focus5"
                                @click="$router.push('/product/view/1#Focus5')"
                                @mouseover="over_machine(1)"
                                @mouseout="over_machine(0)"
                            >
                                <image
                                    :xlink:href="
                                        $store.state.language.index.circle_img
                                            .focus5
                                    "
                                    x="713"
                                    y="1212.6"
                                    height="350"
                                    width="350"
                                    v-show="
                                        over_num != '4' &&
                                            over_num != '5' &&
                                            over_machine_num != '1'
                                    "
                                />
                                <image
                                    :xlink:href="
                                        $store.state.language.index.circle_img
                                            .focus5_shadow
                                    "
                                    x="713"
                                    y="1212.6"
                                    height="350"
                                    width="350"
                                    v-show="
                                        over_num == '4' ||
                                            over_num == '5' ||
                                            over_machine_num == '1'
                                    "
                                />
                            </g>
                            <g
                                class="img_btn CompactB"
                                @click="
                                    $router.push('/product/view/3#CompactB')
                                "
                                @mouseover="over_machine(3)"
                                @mouseout="over_machine(0)"
                            >
                                <image
                                    :xlink:href="
                                        $store.state.language.index.circle_img
                                            .compactb
                                    "
                                    height="350"
                                    width="350"
                                    x="300.3"
                                    y="1131.44"
                                    v-show="
                                        over_num != '4' &&
                                            over_num != '5' &&
                                            over_machine_num != '3'
                                    "
                                />
                                <image
                                    :xlink:href="
                                        $store.state.language.index.circle_img
                                            .compactb_shadow
                                    "
                                    height="350"
                                    width="350"
                                    x="300.3"
                                    y="1131.44"
                                    v-show="
                                        over_num == '4' ||
                                            over_num == '5' ||
                                            over_machine_num == '3'
                                    "
                                />
                            </g>
                            <g
                                class="img_btn linmaxb"
                                @click="$router.push('/product/view/4#LinmaxB')"
                                @mouseover="over_machine(4)"
                                @mouseout="over_machine(0)"
                            >
                                <image
                                    :xlink:href="
                                        $store.state.language.index.circle_img
                                            .linmaxb
                                    "
                                    height="350"
                                    width="350"
                                    x="156.92"
                                    y="750.89"
                                    v-show="
                                        over_num != '4' &&
                                            over_num != '5' &&
                                            over_machine_num != '4'
                                    "
                                />
                                <image
                                    :xlink:href="
                                        $store.state.language.index.circle_img
                                            .linmaxb_shadow
                                    "
                                    height="350"
                                    width="350"
                                    x="156.92"
                                    y="750.89"
                                    v-show="
                                        over_num == '4' ||
                                            over_num == '5' ||
                                            over_machine_num == '4'
                                    "
                                />
                            </g>
                            <g
                                class="img_btn linmaxbtwin"
                                @click="
                                    $router.push('/product/view/6#LinmaxBTwin')
                                "
                                @mouseover="over_machine(6)"
                                @mouseout="over_machine(0)"
                            >
                                <image
                                    :xlink:href="
                                        $store.state.language.index.circle_img
                                            .linmaxbtwin
                                    "
                                    height="350"
                                    width="350"
                                    x="175.04"
                                    y="375.94"
                                    v-show="
                                        over_num != '4' &&
                                            over_machine_num != '6'
                                    "
                                />
                                <image
                                    :xlink:href="
                                        $store.state.language.index.circle_img
                                            .linmaxbtwin_shadow
                                    "
                                    height="350"
                                    width="350"
                                    x="175.04"
                                    y="375.94"
                                    v-show="
                                        over_num == '4' ||
                                            over_machine_num == '6'
                                    "
                                />
                            </g>
                            <g
                                class="img_btn Giant"
                                @click="$router.push('/product/view/11#Giant')"
                                @mouseover="over_machine(11)"
                                @mouseout="over_machine(0)"
                            >
                                <image
                                    :xlink:href="
                                        $store.state.language.index.circle_img
                                            .giant
                                    "
                                    height="350"
                                    width="350"
                                    x="533.37"
                                    y="85.54"
                                    v-show="
                                        over_num != '1' &&
                                            over_num != '2' &&
                                            over_machine_num != '11'
                                    "
                                />
                                <image
                                    :xlink:href="
                                        $store.state.language.index.circle_img
                                            .giant_shadow
                                    "
                                    height="350"
                                    width="350"
                                    x="533.37"
                                    y="85.54"
                                    v-show="
                                        over_num == '1' ||
                                            over_num == '2' ||
                                            over_machine_num == '11'
                                    "
                                />
                            </g>
                            <g
                                class="img_btn Loader"
                                @click="$router.push('/product/view/10#Loader')"
                                @mouseover="over_machine(10)"
                                @mouseout="over_machine(0)"
                            >
                                <image
                                    :xlink:href="
                                        $store.state.language.index.circle_img
                                            .loader
                                    "
                                    height="350"
                                    width="350"
                                    x="892.63"
                                    y="85.54"
                                    v-show="
                                        over_num != '1' &&
                                            over_num != '2' &&
                                            over_machine_num != '10'
                                    "
                                />
                                <image
                                    :xlink:href="
                                        $store.state.language.index.circle_img
                                            .loader_shadow
                                    "
                                    height="350"
                                    width="350"
                                    x="892.63"
                                    y="85.54"
                                    v-show="
                                        over_num == '1' ||
                                            over_num == '2' ||
                                            over_machine_num == '10'
                                    "
                                />
                            </g>
                            <g
                                class="img_btn Saber"
                                @click="$router.push('/product/view/9#Saber')"
                                @mouseover="over_machine(9)"
                                @mouseout="over_machine(0)"
                            >
                                <image
                                    :xlink:href="
                                        $store.state.language.index.circle_img
                                            .saber
                                    "
                                    height="350"
                                    width="350"
                                    x="1250.96"
                                    y="375.94"
                                    v-show="
                                        over_num != '1' &&
                                            over_num != '2' &&
                                            over_machine_num != '9'
                                    "
                                />
                                <image
                                    :xlink:href="
                                        $store.state.language.index.circle_img
                                            .saber_shadow
                                    "
                                    height="350"
                                    width="350"
                                    x="1250.96"
                                    y="375.94"
                                    v-show="
                                        over_num == '1' ||
                                            over_num == '2' ||
                                            over_machine_num == '9'
                                    "
                                />
                            </g>
                            <g
                                class="img_btn rhino"
                                @click="
                                    $router.push('/product/view/7#Rhino1250')
                                "
                                @mouseover="over_machine(7)"
                                @mouseout="over_machine(0)"
                            >
                                <image
                                    :xlink:href="
                                        $store.state.language.index.circle_img
                                            .rhino
                                    "
                                    height="350"
                                    width="350"
                                    x="1269.08"
                                    y="750.89"
                                    v-show="
                                        over_num != '1' &&
                                            over_num != '2' &&
                                            over_num != '3' &&
                                            over_machine_num != '7'
                                    "
                                />
                                <image
                                    :xlink:href="
                                        $store.state.language.index.circle_img
                                            .rhino_shadow
                                    "
                                    height="350"
                                    width="350"
                                    x="1269.08"
                                    y="750.89"
                                    v-show="
                                        over_num == '1' ||
                                            over_num == '2' ||
                                            over_num == '3' ||
                                            over_machine_num == '7'
                                    "
                                />
                            </g>
                            <g
                                class="img_btn Rotor"
                                @click="$router.push('/product/view/2#Rotor')"
                                @mouseover="over_machine(2)"
                                @mouseout="over_machine(0)"
                            >
                                <image
                                    :xlink:href="
                                        $store.state.language.index.circle_img
                                            .rotor
                                    "
                                    height="350"
                                    width="350"
                                    x="1125.7"
                                    y="1131.44"
                                    v-show="
                                        over_num != '5' &&
                                            over_machine_num != '2'
                                    "
                                />
                                <image
                                    :xlink:href="
                                        $store.state.language.index.circle_img
                                            .rotor_shadow
                                    "
                                    height="350"
                                    width="350"
                                    x="1125.7"
                                    y="1131.44"
                                    v-show="
                                        over_num == '5' ||
                                            over_machine_num == '2'
                                    "
                                />
                            </g>
                        </g>
                    </svg>
                </div>
                <div class="col-12 col-lg-4 order-1 order-lg-2">
                    <row
                        v-model="$store.state.language.index.info"
                        :click="click"
                        from="0"
                    >
                    </row>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
text {
    font-family: "TaipeiSansTCBeta-Regular", "Taipei Sans TC Beta";
    font-size: 10px;
}
.img_btn {
    cursor: pointer;
}
.slogan {
    div {
        color: #1461ad;
        font-weight: bold;
    }
}
.circle_box {
    background-image: url("../../public/img/indexbackground.jpg");
    background-repeat: no-repeat;
    background-position: 0 100%;
    background-size: 100%;
}
#content .index .container {
    max-width: 1320px;
}
</style>
<script>
import row from "../components/row.vue";
export default {
    data() {
        return {
            from: 0,
            over_num: "",
            over_machine_num: ""
        };
    },
    mounted() {
        // window.addEventListener("resize", this.get_svg_hw);
        // this.get_svg_hw();
    },
    computed: {},
    methods: {
        over(num) {
            this.over_num = num;
        },
        over_machine(num) {
            this.over_machine_num = num;
        },
        click(from, data) {
            console.log(from, data);
        }
    },
    components: { row }
};
</script>
